import React from 'react';
import { ContainerMenuMobile } from './ContainerMenuMobile';
import { ItemsMenuMobile } from './ItemsMenuMobile';

interface Props {
  itemsMenu: any;
}
export const MenuMobile = ({ itemsMenu }: Props) => {
  return (
    <ContainerMenuMobile>
      <ItemsMenuMobile items={itemsMenu} />
    </ContainerMenuMobile>
  );
};
