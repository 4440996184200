import React from 'react';
import { ContainerMenu } from './ContainerMenu';
import { ListItemMenu } from './ListItemMenu';

interface Items {
  id: string;
  icon: string;
  textLink: string;
  linkTo: string;
  hasSubmenu: boolean;
  submenuItems: Items[];
}
interface Props {
  hasToggleMenu: boolean;
  onClick: () => void;
  itemsMenu: Items[];
  isMobile: boolean;
}
export const Submenu = ({ hasToggleMenu, onClick, isMobile, itemsMenu = [] }: Props) => {
  const renderMenu = () => {
    if (hasToggleMenu) {
      return (
        <ContainerMenu>
          <ListItemMenu onClick={onClick} itemsMenu={itemsMenu} isMobile={isMobile} />
        </ContainerMenu>
      );
    }
  };
  return <>{renderMenu()}</>;
};
