import React from 'react';
import cx from 'classnames';
import styles from './SubMenu.module.css';

interface Props {
  children: React.ReactNode;
}
export const ContainerMenu = ({ children }: Props) => {
  const containerMenu = cx(
    'center br3 bg-white pt12 pb4 pt8-m pb8-m pt8-l pb8-l relative mt4 mb8',
    styles.containerMenu,
  );
  return <div className={containerMenu}>{children}</div>;
};
