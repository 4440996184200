import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { usePrevious, useListener } from '../../../../utils/hooks';
import styles from './MenuMobile.module.css';

interface Props {
  children: React.ReactNode;
  alwaysVisible?: boolean;
}
export const ContainerMenuMobile = ({ children, alwaysVisible }: Props) => {
  const [visible, setVisible] = useState(true);
  const [scrollPos, setScrollPos] = useState<number>(0);
  const prevScrollpos = usePrevious(scrollPos);

  const containerMenuMobile = cx(
    'z-9999 w-100 pl16 pr16 fixed left-0 bg-white transition-ease noPrint',
    {
      'bottom-0': visible || alwaysVisible,
      [styles.tabBarHidden]: !visible && !alwaysVisible,
    },
    styles.shadowMenu,
  );

  if (typeof window !== 'undefined') {
    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };
    useListener(window, 'scroll', handleScroll);
  }

  useEffect(() => {
    if ((prevScrollpos as unknown as number) > scrollPos) {
      setVisible(true);
    }
    // if ((prevScrollpos as unknown as number) <= scrollPos) {
    //   setVisible(false);
    // }
  }, [scrollPos]);

  return <div className={containerMenuMobile}>{children}</div>;
};
