import { axiosInstanceWithAuth } from '../../../services/axios-instance';

export const Localities = {
  getCities: (value: string) =>
    axiosInstanceWithAuth
      .get(`/locality/filterByCityName/${value}`)
      .then(res => {
        return res.data;
      })
      .catch(err => console.error(err)),
};
