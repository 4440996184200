import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { OpenStreetMap } from '../../services/OpenStreetMap';
import { getListUf } from '../../utils/listUf';
import { Button } from '../Button';
import { Select } from '../Select';
import { ButtonMyLocation } from './ButtonMyLocation';
import { Localities } from './services/AutoCompleteCity';

interface Option {
  label: string;
  value: string;
}
interface Popover {
  onConfirm: (location: string) => void;
  location: string;
  locale?: any;
}

export const Content = inject('locale')(
  observer(({ onConfirm, locale }: Popover) => {
    const [suggestions, setSuggestions] = useState([]);
    const [options, setOptions] = useState<Option[]>(suggestions);
    const [selected, setSelected] = useState('');
    const [showNoOptions, setShowNoOptions] = useState(false);

    useEffect(() => {
      setOptions(suggestions);
    }, [suggestions]);

    const position = async (pos: { coords: { latitude: number; longitude: number } }) => {
      const { latitude, longitude } = pos.coords;
      let locationFound = '';
      await OpenStreetMap.reverse(latitude, longitude).then((response: any) => {
        const list = getListUf();
        const uf = list.filter(item => item.uf === response.uf)[0];
        locationFound = `${response.city}/${uf.label}`;
      });
      locale.getLocation(locationFound);
      onConfirm(locationFound);
    };

    const handleUserLocation = () => {
      navigator.geolocation.getCurrentPosition(position, error => console.error(error));
    };

    const handleAutoComplete = (value: string) => {
      setShowNoOptions(false);
      if (!value.length || value.length < 3) {
        return setSuggestions([]);
      }

      setTimeout(async () => {
        await Localities.getCities(value)
          .then((response: any) => {
            if (!response.length) {
              setShowNoOptions(true);
            }
            const localities = response.map((item: string) => ({
              label: item,
              value: item,
            }));
            setOptions(localities);
          })
          .catch(err => console.error(err));
      }, 300);
    };

    const handleConfirm = () => {
      if (selected) {
        locale.getLocation(selected);
      }
      onConfirm(selected);
    };

    const clearSuggestions = () => setSuggestions([]);

    const onChange = (e: any) => {
      setSelected(e.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleConfirm();
      }
    };

    return (
      <div className="flex flex-column">
        <Select
          icon="SvgIconLocation"
          noDropdownIndicator
          onBlur={clearSuggestions}
          onInputChange={handleAutoComplete}
          options={options}
          title="A sua localização é:"
          onChange={onChange}
          showNoOptions={showNoOptions}
          locationDropdown="Popover-children"
        />
        <div className="dib mv16 self-center">
          <ButtonMyLocation handleUserLocation={handleUserLocation} />
        </div>
        <Button
          size="medium"
          classButton="trueno"
          onClick={handleConfirm}
          onKeyDown={handleKeyDown}
        >
          Confirmar
        </Button>
      </div>
    );
  }),
);
