import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import map from 'lodash/map';
import HoverIntent from 'react-hoverintent';
import { Icon } from '../../../../components/Icons';
import { Submenu } from '../Submenu';
import styles from './MenuDesktop.module.css';

export interface Items {
  id: string;
  icon: string;
  textLink: string;
  linkTo: string;
  hasSubmenu: boolean;
  submenuItems: Items[];
}
interface Props {
  items: Items[];
  isMenuOnTop: boolean;
  selectedItem?: string;
}
export const ItemsMenuDesktop = ({ items, isMenuOnTop, selectedItem }: Props) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [rotateArrow180, setRotateArrow180] = useState(true);
  const [hasActiveSubmenu, setHasActiveSubmenu] = useState('');
  const rotateArrow = cx('ml4 transition-ease', { 'rotate-180': !rotateArrow180 });

  const itemList = cx('white pointer flex items-center pr32 relative', styles.hoverLink, {
    abbey: !isMenuOnTop,
  });
  const link = cx('f14 fw4 white  link relative', { abbey: !isMenuOnTop }, styles.link);
  const subMenuPosY = cx('absolute left-0', styles.subMenuPosY);

  const openMenu = (title: string) => {
    setHasActiveSubmenu(title);
    setRotateArrow180(false);
    setToggleMenu(true);
  };

  const onMouseOver = (e: any) => {
    if (e) {
      openMenu(e.title);
    }
  };
  const onMouseOut = () => {
    setRotateArrow180(true);
    setToggleMenu(false);
  };

  const onClickSubmenu = () => {
    console.error('redirect url in submenu');
  };

  const onFocus = (event: any, item: Items) => {
    event.preventDefault();
    if (item.linkTo) {
      return null;
    }
    openMenu(item.textLink);
  };

  const hasSubmenu = (item: Items) => {
    const { submenuItems, textLink } = item;
    if (submenuItems.length === 0) {
      return null;
    }

    return (
      <Icon
        name="SvgIconArrowDown"
        customId={`menu-dd${textLink}`}
        width={12}
        height={12}
        className={hasActiveSubmenu === textLink ? rotateArrow : 'ml4'}
      />
    );
  };
  const subMenu = (item: Items) => {
    const { submenuItems, textLink } = item;
    if (item.submenuItems.length === 0) {
      return null;
    }

    if (hasActiveSubmenu === textLink) {
      return (
        <div className={subMenuPosY}>
          <Submenu
            onClick={onClickSubmenu}
            itemsMenu={submenuItems}
            hasToggleMenu={toggleMenu}
            isMobile={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderItens = () => {
    return map(items, item => {
      const customItemStyle = cx(itemList, {
        [styles.selectedItem]: item.textLink === selectedItem,
      });

      const toFocus = (event: any) => onFocus(event, item);
      return (
        <li key={`${item.id}--${item.textLink}`}>
          <HoverIntent
            onMouseOver={onMouseOver}
            onFocus={onMouseOver}
            onMouseOut={onMouseOut}
            onBlur={onMouseOut}
            sensitivity={10}
            interval={250}
            timeout={250}
          >
            <div className={customItemStyle} title={item.textLink}>
              <Link
                to={item.linkTo}
                activeClassName={styles.activedLink}
                className={link}
                title={item.textLink}
                onClick={item.linkTo === '/' ? () => false : toFocus}
                onBlur={onMouseOut}
                aria-label={item.textLink}
              >
                {item.textLink}
              </Link>
              {subMenu(item)}
              {hasSubmenu(item)}
            </div>
          </HoverIntent>
        </li>
      );
    });
  };
  return (
    <>
      <ul className="list ma0 pa0 flex items-center">{renderItens()}</ul>
    </>
  );
};
