import React, { Component } from 'react';
import cx from 'classnames';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import { LinkWithIcons } from '../../../../components/Link';
import { callAll } from '../../../../utils/callAll';
import { TypeClientStore } from '../../../SubscriptionPlans/store/selectedStore';
import styles from './SubMenu.module.css';

interface OptionsMenu {
  id: string;
  icon: string;
  textLink: string;
  linkTo: string;
  hasSubmenu: boolean;
  submenuItems: OptionsMenu[];
}
interface Props {
  onClick: () => void;
  itemsMenu: OptionsMenu[];
  user?: any;
  selectedStore?: TypeClientStore;
  hiddenCloseButton: boolean;
  isMobile: boolean;
}

interface IState {
  openSubMenu: boolean;
  itemSelected: string | null | undefined;
}

@inject('user')
@inject('selectedStore')
@observer
export class ListItemMenu extends Component<Props, IState> {
  public static defaultProps: Props = {
    itemsMenu: [],
    onClick: () => null,
    hiddenCloseButton: false,
    isMobile: false,
  };

  state: IState = {
    openSubMenu: false,
    itemSelected: null,
  };

  logout = () => {
    this.props.user.cleanUser();
    if (this.props.selectedStore) {
      this.props.selectedStore.cleanClientStore();
    }
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  };

  renderSubItems = (submenuItems: OptionsMenu[]) => {
    const { onClick } = this.props;
    const { openSubMenu }: IState = this.state;
    const list = cx('list ma0 pa0', styles.list);

    const subItems = () => {
      return <ul className={list}>{this.mapItems(submenuItems, onClick)}</ul>;
    };

    if (openSubMenu) {
      return subItems();
    }

    return null;
  };

  mapItems = (itemsMenu: OptionsMenu[], onClick: () => void) => {
    const listItem = cx(
      'bg-center pr16 pl16 relative flex justify-center pointer relative overflow-hidden hover-red abbey',
      styles.listItem,
    );

    const items = () => {
      const itemFilter = filter(itemsMenu, item => {
        const { id } = item;
        if (!this.state.openSubMenu) {
          return true;
        }
        const parentId = id.split('-');

        return (
          (item.id === this.state.itemSelected || parentId[1] === this.state.itemSelected) &&
          this.state.openSubMenu
        );
      });

      return map(itemFilter, item => {
        const { id, hasSubmenu } = item;
        const { isMobile } = this.props;
        const menuSelected = this.state.itemSelected;
        const action = hasSubmenu && isMobile;
        const submenuItems = item.submenuItems || [];
        const selected = menuSelected === id;
        const itemActived = this.state.openSubMenu && selected;
        const iconRightClass = cx('dn-m dn-l', styles.iconRightClass, {
          [styles.rotate180]: itemActived,
        });

        const customSvgStyle = cx({
          [styles.ordersSvg]: item.icon === 'SvgIconOrders',
          [styles.salesSvg]: item.icon === 'SvgIconMySales',
        });

        const click =
          item.linkTo === '/logout' ? callAll(this.logout, onClick) : !hasSubmenu && onClick;
        const openSubmenu = () => {
          if (action) {
            this.setState(state => {
              const { openSubMenu }: IState = state;
              return {
                openSubMenu: !openSubMenu,
                itemSelected: item.id,
              };
            });
          }
        };
        const to = item.linkTo === '/logout' ? null : item.linkTo;

        return (
          <>
            <li
              key={item.id + item.textLink}
              className={cx(listItem, customSvgStyle)}
              onClick={callAll(openSubmenu, click)}
            >
              <LinkWithIcons
                key={item.id + item.textLink}
                to={to}
                iconLeft={item.icon}
                iconRight="SvgIconArrowRight"
                iconLeftClass={styles.iconLeftClass}
                iconRightClass={iconRightClass}
                title={item.textLink}
                action={action}
              >
                {item.textLink}
              </LinkWithIcons>
            </li>
            {itemActived && isMobile && this.renderSubItems(submenuItems)}
          </>
        );
      });
    };

    return items();
  };

  renderItems = () => {
    const list = cx('list ma0 pa0', styles.list);
    const { itemsMenu, onClick } = this.props;

    const items = () => this.mapItems(itemsMenu, onClick);

    return <ul className={list}>{items()}</ul>;
  };

  renderButtonClose = () => {
    const { onClick } = this.props;

    if (this.props.hiddenCloseButton) {
      return null;
    }

    return (
      <div className="flex items-center justify-center pt12 pb12 ma0 dn-m dn-l">
        <a className="red f13 pointer" onClick={onClick} title="Fechar">
          Fechar
        </a>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderItems()}
        {this.renderButtonClose()}
      </>
    );
  }
}
