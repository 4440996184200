import React from 'react';
import { ItemsMenuDesktop, Items } from './ItemsMenuDesktop';

interface Props {
  isMenuOnTop: boolean;
  itemsMenu: Items[];
  selectedItem?: string;
}
export const MenuDesktop = ({ isMenuOnTop, itemsMenu, selectedItem }: Props) => {
  return (
    <ItemsMenuDesktop items={itemsMenu} isMenuOnTop={isMenuOnTop} selectedItem={selectedItem} />
  );
};
