import React, { useState, useEffect } from 'react';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import { inject, observer } from 'mobx-react';
import { OpenStreetMap } from '../../services/OpenStreetMap';
import { ellipsis } from '../../utils/functions';
import { getListUf } from '../../utils/listUf';
import { TogglerButton } from '../Button';
import { Icon } from '../Icons';
import { Popover } from '../Popover';
import { Content } from './Content';

interface Props {
  onChange?: (location: string, previousLocation: string) => void;
  className?: string;
  locale?: any;
}

export const Location: React.FunctionComponent<Props> = inject('locale')(
  observer(({ onChange, className, locale }) => {
    const { city, trackedLocation } = locale;
    const [location, setLocation] = useState<string>(city);
    const [popoverOpened, setPopoverOpened] = useState(false);
    const prevLocation = location;

    useEffect(() => {
      if (!_isEqual(prevLocation, location)) {
        if (onChange) {
          onChange(location, prevLocation);
        }
      }

      if (!trackedLocation) {
        handleUserLocation();
      }
    }, [location]);

    const position = async (pos: { coords: { latitude: number; longitude: number } }) => {
      const { latitude, longitude } = pos.coords;
      let locationFound = '';
      await OpenStreetMap.reverse(latitude, longitude).then((response: any) => {
        const list = getListUf();
        const uf = _filter(list, item => item.uf === response.uf)[0];
        locationFound = `${response?.city}${uf && uf.label ? `/${uf.label}` : ''}`;
      });
      locale.getLocation(locationFound);
      locale.setGeolocation(locationFound);
      setLocation(ellipsis(locationFound, 13));
    };

    const handleUserLocation = () => {
      navigator.geolocation.getCurrentPosition(position, error => console.error(error));
    };

    const handleConfirm = (localLocation: string) => {
      if (localLocation) {
        setLocation(ellipsis(localLocation, 13));
      }
      setPopoverOpened(false);
    };
    const togglePopover = (arg?: boolean | React.SyntheticEvent) => {
      if (typeof arg === 'boolean') {
        return setPopoverOpened(arg);
      }
      setPopoverOpened(!popoverOpened);
    };
    const rightIcon = {
      off: <Icon name="SvgIconArrowDown" customId="toggle" width={12} height={12} />,
      on: <Icon name="SvgIconArrowUp" customId="toggle" width={12} height={12} />,
    };
    const target = (
      <TogglerButton
        leftIcon={<Icon name="SvgIconLocation" width={17} height={17} customId="toggle" />}
        onClick={togglePopover}
        rightIcon={rightIcon}
        state={popoverOpened ? 'on' : 'off'}
        className={className}
        aria-label={`Sua localização atual é ${location}. Clique para buscar veículos em outros locais.`}
      >
        {location}
      </TogglerButton>
    );
    return (
      <Popover
        responsiveMode
        changeOpened={togglePopover}
        element={<Content location={location} onConfirm={handleConfirm} />}
        classes="pt24 ph16 pb16 pb32-m pb32-l"
        opened={popoverOpened}
        target={target}
      />
    );
  }),
);
