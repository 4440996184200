import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import _debounce from 'lodash/debounce';
import ReactDOM from 'react-dom';
import { Manager, Reference, Popper, PopperProps, PopperChildrenProps } from 'react-popper';
import { useListener, usePrevious } from '../../utils/hooks';
import styles from './Popover.module.css';

interface Props {
  changeOpened: (opened: boolean) => void;
  classes?: string;
  element: React.ReactNode;
  opened: boolean;
  placement?: PopperProps['placement'];
  responsiveMode: boolean;
  target: React.ReactNode;
}

interface Target {
  ref: React.Ref<HTMLDivElement>;
}

interface Children {
  ref: React.Ref<HTMLDivElement>;
  style: React.CSSProperties;
  placement: Props['target'];
  scheduleUpdate: PopperChildrenProps['scheduleUpdate'];
}

export const Popover: React.FC<Props> = ({
  changeOpened,
  classes,
  element,
  opened: propsOpened,
  placement: propsPlacement,
  responsiveMode,
  target,
  ...props
}) => {
  const uclass = 'Popover_Children';
  const [opened, setOpened] = useState<Props['opened']>(propsOpened);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  const previousPropsOpened = usePrevious(propsOpened);
  useEffect(() => {
    if (propsOpened !== previousPropsOpened) {
      setOpened(propsOpened);
    }
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);
    }
  }, [propsOpened]);

  const updatePosition = () => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);
    }

    if (!opened) {
      return;
    }

    if (scheduleUpdate) {
      scheduleUpdate();
    }
  };

  const verifyElement = (el: HTMLElement) => {
    if (!el || el.classList.value.match(uclass)) {
      return;
    }

    if (el.nodeName.toLowerCase().match('document|html|body')) {
      return closePopover();
    }

    if (el.parentNode instanceof HTMLElement) {
      verifyElement(el.parentNode);
    }
  };

  const closePopover = (ev?: React.SyntheticEvent) => {
    if (!ev) {
      return changeOpened(false);
    }

    if (opened && ev.target instanceof HTMLElement) {
      verifyElement(ev.target);
    }
  };

  const handleEsc: React.EventHandler<React.KeyboardEvent> = ev => {
    if (opened && (ev.key.toLowerCase().includes('esc') || ev.keyCode === 27)) {
      closePopover();
      ev.stopPropagation();
    }
  };

  // Listeners

  const debounced = _debounce(updatePosition, 150);

  if (typeof window !== 'undefined') {
    useListener(window.document.body, 'scroll', debounced);
    useListener(window, 'orientationchange', debounced);
    useListener(window, 'resize', debounced);

    useListener(window, 'click', closePopover);

    useListener(window, 'keydown', handleEsc);
  }

  // Elements to render

  const reference = ({ ref }: Target) => (
    <div className={classnames('di')} ref={ref}>
      {target}
    </div>
  );

  let scheduleUpdate: Children['scheduleUpdate'];
  const children = ({
    ref,
    style,
    placement,
    scheduleUpdate: scheduleUpdateChildren,
  }: Children) => {
    scheduleUpdate = scheduleUpdateChildren;
    const childrenClassNames = classnames(
      'z-max',
      {
        [`bg-white br3 ${classes}`]: !responsiveMode || windowWidth >= 1024,
        [`br3 br--bottom ${styles.responsiveMode}`]: responsiveMode && windowWidth < 1024,
      },
      styles.container,
      uclass,
    );

    const overlay = responsiveMode && windowWidth < 1024 && (
      <div className={classnames('absolute--fill bg-black-30 fixed z-max', styles.overlay)} />
    );

    const close = () => closePopover();

    const childrenToRender =
      (responsiveMode && windowWidth < 1024 && (
        <>
          <div className={classnames('bg-white br3 br--top', classes)}>{element}</div>
          <div className={classnames('bg-white br3 br--bottom bt pb12', styles.closeContainer)}>
            <button
              className="bg-transparent bn f13 pa12 pointer red trueno underline-hover w-100"
              onClick={close}
            >
              Fechar
            </button>
          </div>
        </>
      )) ||
      element;

    return (
      <>
        {overlay}
        <div className={childrenClassNames} data-placement={placement} ref={ref} style={style}>
          {childrenToRender}
        </div>
      </>
    );
  };

  if (typeof window !== 'undefined' && !window.document.querySelector('#portal')) {
    const portalDiv = document.createElement('div');
    portalDiv.id = 'portal';
    document.body.appendChild(portalDiv);
  }

  const portal = typeof window !== 'undefined' && document.querySelector('#portal');
  let portalToRender = null;
  if (portal) {
    const PopperToRender = (
      <Popper placement={propsPlacement} {...props}>
        {children}
      </Popper>
    );
    portalToRender = ReactDOM.createPortal(PopperToRender, portal);
  }

  return (
    <Manager>
      <Reference>{reference}</Reference>
      {opened && portalToRender}
    </Manager>
  );
};

Popover.defaultProps = {
  opened: false,
  responsiveMode: true,
};
