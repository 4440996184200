import React, { useState } from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { Icon } from '../../../../components/Icons';
import { MyAccount } from '../MyAccount';
import { Submenu } from '../Submenu';
import styles from './MenuMobile.module.css';

interface Items {
  id: string;
  icon: string;
  textLink: string;
  linkTo: string;
  hasSubmenu: boolean;
  submenuItems: Items[];
}
interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  items: Items[];
}
export const ItemsMenuMobile = ({ items }: Props) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const [hasActiveSubmenu, setHasActiveSubmenu] = useState();
  const subMenuPosY = cx('absolute top-0', styles.subMenuPosY);

  const onClickSubmenu = () => {
    setToggleMenu(prevToggleMenu => !prevToggleMenu);
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    setHasActiveSubmenu(e.currentTarget.title);
    setToggleMenu(prevToggleMenu => !prevToggleMenu);
  };

  const subMenu = (item: Items) => {
    const { submenuItems, textLink } = item;
    if (item.submenuItems.length === 0) {
      return null;
    }
    if (hasActiveSubmenu === textLink) {
      return (
        <div className={subMenuPosY}>
          <Submenu
            onClick={onClickSubmenu}
            itemsMenu={submenuItems}
            hasToggleMenu={toggleMenu}
            isMobile={false}
          />
        </div>
      );
    }
    return null;
  };
  const renderItens = () => {
    const reorderedItems = [...items].sort(a => (a.textLink === 'Vender' ? -1 : 0));

    return map(reorderedItems, (item, idx) => {
      const { id, textLink, icon } = item;

      const itemClasses = cx('boulder hover-red pointer relative', {
        [styles.highlight]: textLink === 'Vender',
      });
      return (
        <div key={`${id}--${idx}`}>
          <li className={itemClasses} title={textLink} onClick={handleClick}>
            <span className="flex flex-column items-center f11 fw7 ttu" title={textLink}>
              <Icon name={icon} className="mb4" />
              <span>{textLink}</span>
            </span>
          </li>
          {subMenu(item)}
        </div>
      );
    });
  };
  return (
    <ul className="list ma0 pa0 flex justify-around pb16 pt8">
      {renderItens()}
      <MyAccount onClick={handleClick} currentClick={hasActiveSubmenu} />
    </ul>
  );
};
