import axios from 'axios';

const axiosInstanceOpenStreetMap = axios.create({
  baseURL: process.env.OPEN_STREET_MAP,
});

export const OpenStreetMap = {
  reverse: (latitude: number, longitude: number) =>
    axiosInstanceOpenStreetMap
      .get('/reverse', {
        params: {
          format: 'jsonv2',
          lat: latitude,
          lon: longitude,
          zoom: '10',
        },
      })
      .then(res => {
        return {
          city: res.data.name,
          uf: res.data.address.state,
        };
      })
      .catch(err => console.error(err)),
};
