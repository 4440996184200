import React from 'react';
import { ButtonWithIcon } from '../Button';
import { Icon } from '../Icons';

interface Props {
  handleUserLocation: () => void;
}

export const ButtonMyLocation = ({ handleUserLocation }: Props) => {
  return (
    <ButtonWithIcon
      icon={<Icon name="SvgIconLookmaps" width={20} height={20} />}
      onClick={handleUserLocation}
    >
      Usar minha localização atual
    </ButtonWithIcon>
  );
};
