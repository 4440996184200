import find from 'lodash/find';
import map from 'lodash/map';

interface ISubmenu {
  name: string;
  url: string;
  type: number;
}

export const menuFormatted = (data: any) => {
  const formatSubmenus = (submenus: any) =>
    map(submenus, (item, idx) => {
      const { name, icon, url_submenu } = item;
      return {
        id: `${name}${idx}`,
        icon,
        textLink: name,
        linkTo: url_submenu,
        hasSubmenu: false,
        submenuItems: [],
      };
    });

  return map(data, (item, idx) => {
    const { name_item_menu, url_menu, url, icon_menu, submenu_menu } = item;

    return {
      id: `${name_item_menu}${idx}`,
      textLink: name_item_menu,
      linkTo: url_menu,
      hasSubmenu: !url,
      icon: icon_menu,
      submenuItems: formatSubmenus(submenu_menu),
    };
  });
};

export const adapterMyAccount = (data: any, accountType: string) => {
  const filterMyAccount = find(data, (item: any) => accountType === item.tipo_conta);
  const { submenu_minha_conta } = filterMyAccount;

  const formatSubmenuItems = (parentId: string, itemSubmenu: ISubmenu[]) =>
    map(itemSubmenu, (submenu, idx1) => {
      const { name, url, type } = submenu;
      return {
        id: `${name}${idx1}-${parentId}`,
        icon: '',
        textLink: name,
        linkTo: url,
        hasSubmenu: false,
        submenuItems: [],
        type,
      };
    });

  const formatSubmenus = (submenus: any) => {
    return map(submenus, (item, idx) => {
      const {
        item_submenu_minha_conta,
        icon_submenu_minha_conta,
        url_submenu_minha_conta,
        item_submenu,
      } = item;

      const hasSubmenu = item_submenu !== null;
      const id = `${item_submenu_minha_conta}${idx}`;

      return {
        id,
        icon: icon_submenu_minha_conta,
        textLink: item_submenu_minha_conta,
        linkTo: url_submenu_minha_conta,
        hasSubmenu,
        submenuItems: hasSubmenu ? formatSubmenuItems(id, item_submenu) : [],
      };
    });
  };
  return formatSubmenus(submenu_minha_conta) || [];
};
