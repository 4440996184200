import React, { useState } from 'react';
import cx from 'classnames';
import { Icon } from '../../../../../components/Icons';
import { callAll } from '../../../../../utils/callAll';
import { Submenu } from '../../Submenu';
import styles from '../MyAccount.module.css';

interface Props {
  onClick: any;
  data: any;
  currentClick?: string;
  isMobile: boolean;
}
export const MyAccountMobile = ({ data, onClick, currentClick, isMobile }: Props) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const [hasActiveSubmenu, setHasActiveSubmenu] = useState();
  const subMenuPosY = cx('absolute top-0', styles.subMenuPosY);

  const onClickSubmenu = () => {
    setToggleMenu(prevToggleMenu => !prevToggleMenu);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setHasActiveSubmenu(e.currentTarget.title);
    setToggleMenu(prevToggleMenu => !prevToggleMenu);
  };

  const subMenu = (itemsMenu: any) => {
    if (itemsMenu.length === 0) {
      return null;
    }
    if (hasActiveSubmenu === currentClick) {
      return (
        <div className={subMenuPosY}>
          <Submenu
            onClick={onClickSubmenu}
            itemsMenu={itemsMenu}
            hasToggleMenu={toggleMenu}
            isMobile={isMobile}
          />
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <li
        className="boulder hover-red pointer relative"
        title="Conta"
        onClick={callAll(handleClick, onClick)}
      >
        <span className="flex flex-column items-center f11 fw7 ttu" title="Conta">
          <Icon name="SvgIconName" className="mb4" />
          <span>Conta</span>
        </span>
      </li>
      {subMenu(data)}
    </>
  );
};
