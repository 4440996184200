import React, { useState } from 'react';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import HoverIntent from 'react-hoverintent';
import { ButtonIcon } from '../../../../components/Button';
import { callAll } from '../../../../utils/callAll';
import { adapterMyAccount } from '../../utils/formatMenu';
import { Submenu } from '../Submenu';
import { MyAccountMobile } from './components/MyAccountMobile';
import styles from './MyAccount.module.css';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  data?: any;
  currentClick?: string;
  user?: any;
  title?: string;
}
export const RenderMyAccount = ({ onClick, currentClick, data, user, title }: Props) => {
  const { token, fantasyName, name } = user.profile;
  let type = 'unlogged_common';
  if (token) {
    const isAdmin = get(JSON.parse(localStorage.getItem('user') || ''), 'profile.admin', false);
    type = isAdmin ? 'admin' : 'logged_common';
  }
  const [rotateArrow180, setRotateArrow180] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);

  const newDataFilter = adapterMyAccount(data, type);

  const handleClick = () => {
    setRotateArrow180(!rotateArrow180);
    setToggleMenu(!toggleMenu);
  };
  const onMouseOver = () => {
    setRotateArrow180(!rotateArrow180);
    setToggleMenu(true);
  };
  const onMouseOut = () => {
    setRotateArrow180(!rotateArrow180);
    setToggleMenu(false);
  };
  const rotateArrow = cx('transition-ease', { 'rotate-180': !rotateArrow180 });
  const containerBtnIcon = cx('relative ml8', styles.containerBtnIcon);

  const truncate = (res: string) => {
    if (!res) {
      return null;
    }
    const textBtnTruncate = res.split(' ');
    return textBtnTruncate.length >= 2
      ? `${textBtnTruncate[0]} ${textBtnTruncate[1]}`
      : textBtnTruncate[0];
  };

  const accountName = truncate(fantasyName || name);

  return (
    <div>
      <div className="dn-m dn-l">
        <MyAccountMobile
          onClick={onClick}
          data={newDataFilter}
          currentClick={currentClick}
          isMobile
        />
      </div>
      <div className="dn dn-ns db-m db-l">
        <HoverIntent
          onMouseOver={onMouseOver}
          onFocus={onMouseOver}
          onMouseOut={onMouseOut}
          onBlur={onMouseOut}
          sensitivity={10}
          interval={250}
          timeout={250}
        >
          <div className={containerBtnIcon}>
            <ButtonIcon
              size="medium"
              iconLeft="SvgIconName"
              iconRight="SvgIconArrowDown"
              iconRightClass={rotateArrow}
              onClick={callAll(handleClick, onClick)}
              title={title}
              aria-label={accountName ? 'Acessar conta' : 'Fazer login'}
            >
              <span className="pl4 pr4">{accountName || 'Minha Conta'}</span>
            </ButtonIcon>
            <div className="absolute right-0">
              <Submenu
                onClick={handleClick}
                hasToggleMenu={toggleMenu}
                itemsMenu={newDataFilter}
                isMobile={false}
              />
            </div>
          </div>
        </HoverIntent>
      </div>
    </div>
  );
};
export const MyAccount = inject('user')(
  observer((props: Props) => {
    const myAccountData = graphql`
      query {
        allDirectusMinhaConta {
          nodes {
            submenu_minha_conta {
              item_submenu_minha_conta
              icon_submenu_minha_conta
              url_submenu_minha_conta
              item_submenu {
                name
                url
                type
              }
            }
            minha_conta_item
            icon_minha_conta
            tipo_conta
          }
        }
      }
    `;
    const renderMyAccount = (data: any) => {
      const {
        allDirectusMinhaConta: { nodes },
      } = data;
      return <RenderMyAccount data={nodes} {...props} title="my-account" />;
    };
    return <StaticQuery query={myAccountData} render={renderMyAccount} />;
  }),
);
