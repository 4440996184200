import React from 'react';
import cx from 'classnames';
import styles from './HeaderMenu.module.css';

interface Props {
  children: React.ReactNode;
  isVisible: boolean;
  isMenuOnTop: boolean;
}
export const ContainerHeader = ({ children, isVisible, isMenuOnTop }: Props) => {
  const containerHeader = cx(
    'z-9999 w-100 fixed-ns left-0 flex items-center justify-between transition-ease noPrint',
    {
      'bg-white': !isMenuOnTop,
      'bg-transparent': isMenuOnTop,
      [styles.shadowHeader]: !isMenuOnTop,
      'top-0': isVisible,
      [styles.headerHidden]: !isVisible && !isMenuOnTop,
    },
  );
  return <div className={containerHeader}>{children}</div>;
};
