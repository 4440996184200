import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { HeaderMenu } from './components/HeaderMenu';
import { MenuMobile } from './components/MenuMobile';
import { menuFormatted } from './utils/formatMenu';

interface MenuProps {
  selectedItem?: string;
  menuHasTwoColors?: boolean;
  data?: any;
  top?: boolean;
}
export const RenderMenu = ({ data, selectedItem, menuHasTwoColors, top = true }: MenuProps) => {
  const newData = menuFormatted(data);
  const renderHeaderMenu = () => {
    if (top) {
      return (
        <HeaderMenu
          itemsMenu={newData}
          menuHasTwoColors={menuHasTwoColors}
          selectedItem={selectedItem}
        />
      );
    }
  };
  return (
    <>
      {renderHeaderMenu()}
      <div className="db db-ns dn-m dn-l noPrint">
        <MenuMobile itemsMenu={newData} />
      </div>
    </>
  );
};

export const Menu = ({ ...props }: MenuProps) => {
  const menuData = graphql`
    query {
      allDirectusMenu {
        nodes {
          submenu_menu {
            name
            url_submenu
            icon
          }
          name_item_menu
          url_menu
          icon_menu
        }
      }
    }
  `;

  const renderMenu = (data: any) => {
    const {
      allDirectusMenu: { nodes },
    } = data;
    return <RenderMenu data={nodes} {...props} />;
  };

  return <StaticQuery query={menuData} render={renderMenu} />;
};
